<template>
  <v-row>

    <v-col cols="7">
      <v-select
        label="Type of budget to be evaluated by saturation : "
        v-model="typeOfBudgetSaturation"
        :items="input.typeOfBudget"
        :disabled="(indifferent && allowIndifferent) || disabled"
        :rules="!allowIndifferent ? [rules.required] : []"
        :tab="tab"
        :persistent-hint="true"
        :hint="getHint()"
        :error-messages="errorMessageTypeOfBudgetSaturation()"
      >
      </v-select>
    </v-col>

    <v-col cols="4" offset-sm="1" offset-md="1" v-if="allowIndifferent">
      <v-checkbox
        label="indifferent"
        v-model="indifferent"
        :disabled="disabled"
      >
      </v-checkbox>
    </v-col>

  </v-row>
</template>

<script>
import { rulesMixin } from '../../../../mixins/rulesMixin'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'

export default {
  name: 'TypeOfBudget',
  props: {
    value: {
      type: String
    },
    allowIndifferent: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tab: {
      type: String,
      default: undefined
    }
  },
  mixins: [rulesMixin, checkPremepValidatorMixins],
  components: {

  },
  data: function () {
    return {
      typeOfBudgetSaturation: '',
      indifferent: false,
      input: {
        typeOfBudget: [
          { text: 'Money', value: 'money' },
          { text: 'Impressions', value: 'imp' }
        ]
      }
    }
  },
  created: function () {

  },
  mounted: function () {
    this.typeOfBudgetSaturation = this.value
  },
  methods: {
    errorMessageTypeOfBudgetSaturation () {
      let errors = []
      if (!this.isWarningCPMValidator()) {
        errors = [...this.getErrorRelatedToFieldCPMValidator(['obj_watcher.saturation.budget_type']), ...errors]
      }
      return errors
    },
    getHint () {
      if (this.isWarningCPMValidator()) {
        const errors = this.getWarningRelatedToFieldCPMValidator(['obj_watcher.saturation.budget_type'])
        if (errors.length > 0) {
          return errors.join(' ')
        }
      }
      return ''
    }
  },
  computed: {
    isUserDebugger () {
      return this.$store.getters.isUserDebugger
    }
  },
  watch: {
    'typeOfBudgetSaturation': {
      immediate: true,
      deep: true,
      handler: function (newValue) {
        if (this.allowIndifferent) {
          if (!newValue || newValue.trim() === '') {
            if (this.indifferent === false) {
              this.indifferent = true
            }
          } else {
            if (this.indifferent === true) {
              this.indifferent = false
            }
          }
        }

        this.$emit('input', newValue)
      }
    },
    'indifferent': {
      immediate: true,
      deep: true,
      handler: function (bool) {
        if (!this.allowIndifferent) {
          return
        }
        if (bool) {
          this.typeOfBudgetSaturation = ''
        } else {
          this.typeOfBudgetSaturation = this.value !== '' ? this.value : 'money'
        }
      }
    },
    value (val) {
      if (val !== this.typeOfBudgetSaturation) {
        this.typeOfBudgetSaturation = val
      }
    }
  }
}
</script>

<style>
</style>
