<template>
  <v-row class="remove-inline-div" no-gutters>
    <v-col class="remove-inline-div">
      {{label}} :
    </v-col>
    <v-btn :disabled="disabled" @click="emitToggle" small fab>
      <v-icon :class="isDspOn ? 'item-is-on' : 'item-is-off'">power_settings_new</v-icon>
    </v-btn>
    <WarningMessageBox
      :has-warning="hasWarning"
      :message-warning="messageWarning"
    >
    </WarningMessageBox>
  </v-row>
</template>

<script>
import WarningMessageBox from '@/components/TableComponents/Form/FormFragments/WarningMessageBox'
export default {
  name: 'ItemOnOff.vue',
  props: {
    label: {
      type: String,
      required: true
    },
    hasWarning: {
      type: Boolean,
      default: false
    },
    messageWarning: {
      type: String,
      default: ''
    },
    isDspOn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WarningMessageBox
  },
  data: function () {
    return {

    }
  },
  methods: {
    emitToggle () {
      if (this.readOnly) {
        return
      }
      this.$emit('toggle')
    }
  }
}
</script>

<style scoped>
@media only screen
and (max-width: 1823px) {
  .remove-inline-div {
    font-size: 8px !important;
  }
}

@media only screen
and (min-width: 1824px) {
  .remove-inline-div {
    font-size: 10px !important;
  }
}
</style>
