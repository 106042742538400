
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class HelpTooltip extends Vue {
  @Prop({ required: true })
  text!: string

  @Prop({ default: false })
  info!: boolean

  get icon () {
    return this.info ? 'info' : 'help'
  }
}
